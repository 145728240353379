import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // headshot: "https://thispersondoesnotexist.com",
  headshot: "",
  headshotActive: true,
  name: "Your Name",
  nameActive: true,
  handle: "your_linkedin_handle",
  handleActive: true,
  removeWatermark: true,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setSettings } = settingsSlice.actions;
export default settingsSlice.reducer;
