import React, { useContext, useEffect, useState } from "react";
import { ADcontext } from "../../context/adContext";
import { Modal, Button, Nav, Navbar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { DropdowNavBarProfile } from "../atoms/DropdowNavBar";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

export default function NavBar() {
  const { state } = useContext(ADcontext);
  const [showModal, setShowModal] = useState(false);
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [permissions, setPermissions] = useState(false);
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const rol = Number(localStorage.getItem("rol"));

    if (state.isAuth && rol === 10) {
      setPermissions(true);
    }
  }, [state.isAuth]);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleNavLinkClick = () => {
    setNavbarExpanded(false);
  };

  return (
    <>
      <Navbar
        bg="dark"
        variant="dark"
        expand="lg"
        fixed="top"
        expanded={navbarExpanded}
        onToggle={() => setNavbarExpanded(!navbarExpanded)}
      >
        <div className="container">
          <Navbar.Brand as={Link} to="/">
            NexPost
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link onClick={handleNavLinkClick} as={Link} to="/#home">
                Home
              </Nav.Link>
              <Nav.Link
                onClick={handleNavLinkClick}
                as={Link}
                to="/#features"
              >
                Features
              </Nav.Link>
              <Nav.Link
                onClick={handleNavLinkClick}
                as={Link}
                to="/#pricing"
              >
                Pricing
              </Nav.Link>
              <Nav.Link
                onClick={handleNavLinkClick}
                as={Link}
                to="/#contact"
              >
                Contact
              </Nav.Link>
            </Nav>
            <div className="d-flex ms-3">
              {state.isAuth ? (
                <DropdowNavBarProfile
                  handlrePermission={() => setPermissions(false)}
                />
              ) : (
                <Button
                  variant="primary"
                  onClick={() => {
                    {
                      handleShow();
                      handleNavLinkClick();
                    }
                  }}
                >
                  Login
                </Button>
              )}
            </div>
          </Navbar.Collapse>
        </div>
      </Navbar>

      {/* Login Modal */}
      <Modal show={showModal} onHide={handleClose} backdrop="static" className="mt-5">
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            <Button
              className="mb-2"
              variant="danger"
              onClick={() => loginWithRedirect()}
            >
              Login with Google
            </Button>
            {/* <Button
              className="mb-2"
              variant="dark"
              onClick={() => alert("Apple Login")}
            >
              Login with Apple
            </Button>
            <Button variant="primary" onClick={() => alert("Facebook Login")}>
              Login with Facebook
            </Button> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
