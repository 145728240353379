import { useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { ADcontext } from "../context/adContext";

const useAuthRedirect = (isAuthRedirectNeeded) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { dispatch } = useContext(ADcontext);

  useEffect(() => {
    const redirect = async () => {
      if (!isLoading) {
        if (!isAuthenticated) {
          // Redirect if not authenticated
          navigate("/");
        } else {
          // Get token and update context if authenticated
          try {
            const token = await getAccessTokenSilently();
            localStorage.setItem("token", token);

            dispatch({
              type: "ADD_TOKEN",
              value: {
                token: token,
                author: false,
              },
            });
            dispatch({
              type: "CHANGE_AUTH",
              value: {
                isAuth: true,
              },
            });
          } catch (error) {
            console.error("Failed to get token:", error);
          }
        }
      }
    };

    if (isAuthRedirectNeeded) {
      redirect();
    }
  }, [
    isAuthenticated,
    isLoading,
    navigate,
    getAccessTokenSilently,
    dispatch,
    isAuthRedirectNeeded,
  ]);
};

export default useAuthRedirect;
